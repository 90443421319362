<template>
  <Page class="page-shops page-edit-shop">
    <template #title>Modifica shop</template>

    <template #title-extra>
      <router-link class="btn btn-outline-light" :to="{ name: 'shops.show' }">Annulla modifica</router-link>
    </template>

    <template #default>
      <ShopForm :feedback.sync="feedback" :settings="shopToEdit" @submitForm="submit" edit />
    </template>
  </Page>
</template>

<script>

import Page from '@/views/components/Private/Page.vue';
import ShopForm from '@/views/components/Form/ShopWebSettingsForm.vue';

export default {
  components: {
    Page,
    ShopForm,
  },
  props: {
    shop: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    shopToEdit: {},
    feedback: null,
  }),
  watch: {
    shop (newValue) {
      this.updateShopToEdit(newValue);
    },
  },
  methods: {
    updateShopToEdit (shop) {
      this.shopToEdit = {
        ...shop,
        ...this.shopToEdit,
      };
    },
    submit (shopToEdit) {
      const shop = { ...shopToEdit };

      this.$api.updateShop(shop.id, shop)
        .then(res => {
          this.feedback = true;
          this.$emit('update:shop', res.data);
          this.shopToEdit = {};
          this.$router.push({ name: 'shops.show' });
        })
        .catch(err => {
          this.feedback = false;
          this.$log.error(err);
        })
      ;
    },
  },
  mounted () {
    this.updateShopToEdit(this.shop);
  },
};

</script>
